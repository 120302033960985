@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=menu");

body {
  --red: #F12716;
  --off-white: #f5f5f7;
  --background: #fff;
  --light-grey: #dadce0;
  --background-mono: var(--background);
  --link-hover-grey: #5f6368;
  --text-primary: var(--text-primary);
  --text-secondary: rgb(45, 44, 42);
  --text-header: var(--text-primary);
  --transition: 0s linear
}

body:after {
  content: "sm";
  display: none;
}

@media (min-width: 600px) {
  body:after {
    content: "md";
  }
}

@media (min-width: 1024px) {
  body:after {
    content: "lg";
  }
}

@media (min-width: 1440px) {
  body:after {
    content: "xl";
  }
}

.job-description__details p {
  font-size: 1.2rem;
  line-height: 1.5;
  padding-inline-start: 16px;
  margin-top: 16px;
  color: #000;
}

.sponsor-icon {
  align-content: center;
}

.glue-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-content: center;
  align-items: center;
  align-self: flex-start;
  border: 1px solid transparent;
  border-radius: 48px;
  display: inline-flex;
  flex-flow: row nowrap;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: space-around;
  letter-spacing: .5px;
  line-height: 1.5;
  margin: 8px 0;
  max-width: 380px;
  min-height: 48px;
  min-width: 96px;
  overflow: hidden;
  padding: 0px 24px;
  text-align: center;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  transition: all 0s;
  vertical-align: middle;
}

.glue-button.glue-button--medium-emphasis {
  background-color: #fff;
  border-color: var(--red);
  box-shadow: none;
  color: var(--red);
}

.glue-button.glue-button--medium-emphasis:hover {
  background-color: rgb(60, 34, 32);
  border-color: var(--red);
  color: white;
}

.glue-button.glue-button--low-emphasis {
  background-color: transparent;
  color: var(--red);
  min-width: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.glue-button.glue-button--low-emphasis:hover {
  background-color: rgba(26, 115, 232, .04);
  box-shadow: none;
  color: var(--red);
}

.glue-footer__site-links, .glue-page {
  margin: 0 28px;
}

@media (min-width: 600px) {
  .glue-footer__site-links, .glue-page {
    margin: 0 40px;
  }
}

@media (min-width: 1024px) {
  .glue-footer__site-links, .glue-page {
    margin: 0 72px;
  }
}

@media (min-width: 1440px) {
  .glue-footer__site-links, .glue-page {
    margin: 0 auto;
    max-width: 1296px;
  }
}

.glue-icon {
  fill: currentColor;
  display: inline-block;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, sans-serif;
}

footer, header, nav, section {
  display: block;
}

a {
  background: transparent;
}

a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box
}

pre {
  overflow: auto
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  cursor: pointer;
}

div, h1, h2, li, p, ul {
  margin: 0;
  padding: 0;
}

a, a:after, a:before, br, br:after, br:before, button, button:after, button:before, div, div:after, div:before, footer, footer:after, footer:before, h1, h1:after, h1:before, h2, h2:after, h2:before, header, header:after, header:before, img, img:after, img:before, li, li:after, li:before, nav, nav:after, nav:before, noscript, noscript:after, noscript:before, p, p:after, p:before, script, script:after, script:before, section, section:after, section:before, span, span:after, span:before, svg, svg:after, svg:before, ul, ul:after, ul:before {
  box-sizing: border-box;
}

img {
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: middle
}

@supports (display:grid) {
  .glue-grid {
    grid-column-gap: 28px;
    display: grid;
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-1 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-1 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-2 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-3 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-5 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-5 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-6 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-6 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-7 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-7 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-8 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-8 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-9 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-9 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-10 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-10 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-11 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-11 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-12 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-12 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-18 {
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-18 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--align-top {
    align-self: start
  }

  .glue-grid .glue-grid__col--align-middle {
    align-self: center
  }

  .glue-grid .glue-grid__col--align-bottom {
    align-self: end
  }

  .glue-grid .glue-grid__col--span-0, .glue-grid .glue-grid__col--span-0-sm {
    display: none
  }

  .glue-grid .glue-grid__col--span-1-sm {
    display: block;
    grid-column-end: span 1
  }

  .glue-grid .glue-grid__col--span-1-sm .glue-grid {
    grid-template-columns:repeat(1, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2-sm {
    display: block;
    grid-column-end: span 2
  }

  .glue-grid .glue-grid__col--span-2-sm .glue-grid {
    grid-template-columns:repeat(2, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3-sm {
    display: block;
    grid-column-end: span 3
  }

  .glue-grid .glue-grid__col--span-3-sm .glue-grid {
    grid-template-columns:repeat(3, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4-sm {
    display: block;
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4-sm .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  @media (min-width: 600px) {
    .glue-grid {
      grid-column-gap: 40px;
      grid-template-columns:repeat(18, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-1 {
      display: block;
      grid-column-end: span 1
    }

    .glue-grid .glue-grid__col--span-1 .glue-grid {
      grid-template-columns:repeat(1, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-2 {
      display: block;
      grid-column-end: span 2
    }

    .glue-grid .glue-grid__col--span-2 .glue-grid {
      grid-template-columns:repeat(2, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-3 {
      display: block;
      grid-column-end: span 3
    }

    .glue-grid .glue-grid__col--span-3 .glue-grid {
      grid-template-columns:repeat(3, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-4 {
      display: block;
      grid-column-end: span 4
    }

    .glue-grid .glue-grid__col--span-4 .glue-grid {
      grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-5 {
      display: block;
      grid-column-end: span 5
    }

    .glue-grid .glue-grid__col--span-5 .glue-grid {
      grid-template-columns:repeat(5, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-6 {
      display: block;
      grid-column-end: span 6
    }

    .glue-grid .glue-grid__col--span-6 .glue-grid {
      grid-template-columns:repeat(6, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-7 {
      display: block;
      grid-column-end: span 7
    }

    .glue-grid .glue-grid__col--span-7 .glue-grid {
      grid-template-columns:repeat(7, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-8 {
      display: block;
      grid-column-end: span 8
    }

    .glue-grid .glue-grid__col--span-8 .glue-grid {
      grid-template-columns:repeat(8, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-9 {
      display: block;
      grid-column-end: span 9
    }

    .glue-grid .glue-grid__col--span-9 .glue-grid {
      grid-template-columns:repeat(9, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-10 {
      display: block;
      grid-column-end: span 10
    }

    .glue-grid .glue-grid__col--span-10 .glue-grid {
      grid-template-columns:repeat(10, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-11 {
      display: block;
      grid-column-end: span 11
    }

    .glue-grid .glue-grid__col--span-11 .glue-grid {
      grid-template-columns:repeat(11, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-12 {
      display: block;
      grid-column-end: span 12
    }

    .glue-grid .glue-grid__col--span-12 .glue-grid {
      grid-template-columns:repeat(12, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-18 {
      display: block;
      grid-column-end: span 18
    }

    .glue-grid .glue-grid__col--span-18 .glue-grid {
      grid-template-columns:repeat(18, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-0-md {
      display: none
    }

    .glue-grid .glue-grid__col--span-1-md {
      display: block;
      grid-column-end: span 1
    }

    .glue-grid .glue-grid__col--span-1-md .glue-grid {
      grid-template-columns:repeat(1, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-2-md {
      display: block;
      grid-column-end: span 2
    }

    .glue-grid .glue-grid__col--span-2-md .glue-grid {
      grid-template-columns:repeat(2, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-3-md {
      display: block;
      grid-column-end: span 3
    }

    .glue-grid .glue-grid__col--span-3-md .glue-grid {
      grid-template-columns:repeat(3, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-4-md {
      display: block;
      grid-column-end: span 4
    }

    .glue-grid .glue-grid__col--span-4-md .glue-grid {
      grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-5-md {
      display: block;
      grid-column-end: span 5
    }

    .glue-grid .glue-grid__col--span-5-md .glue-grid {
      grid-template-columns:repeat(5, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-6-md {
      display: block;
      grid-column-end: span 6
    }

    .glue-grid .glue-grid__col--span-6-md .glue-grid {
      grid-template-columns:repeat(6, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-7-md {
      display: block;
      grid-column-end: span 7
    }

    .glue-grid .glue-grid__col--span-7-md .glue-grid {
      grid-template-columns:repeat(7, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-8-md {
      display: block;
      grid-column-end: span 8
    }

    .glue-grid .glue-grid__col--span-8-md .glue-grid {
      grid-template-columns:repeat(8, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-9-md {
      display: block;
      grid-column-end: span 9
    }

    .glue-grid .glue-grid__col--span-9-md .glue-grid {
      grid-template-columns:repeat(9, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-10-md {
      display: block;
      grid-column-end: span 10
    }

    .glue-grid .glue-grid__col--span-10-md .glue-grid {
      grid-template-columns:repeat(10, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-11-md {
      display: block;
      grid-column-end: span 11
    }

    .glue-grid .glue-grid__col--span-11-md .glue-grid {
      grid-template-columns:repeat(11, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-12-md {
      display: block;
      grid-column-end: span 12
    }

    .glue-grid .glue-grid__col--span-12-md .glue-grid {
      grid-template-columns:repeat(12, minmax(5px, 1fr))
    }

    .glue-grid .glue-grid__col--span-18-md {
      display: block;
      grid-column-end: span 18
    }

    .glue-grid .glue-grid__col--span-18-md .glue-grid {
      grid-template-columns:repeat(18, minmax(5px, 1fr))
    }
  }@media (min-width: 1024px) {
  .glue-grid {
    grid-column-gap: 48px
  }

  .glue-grid .glue-grid__col--span-1 {
    display: block;
    grid-column-end: span 1
  }

  .glue-grid .glue-grid__col--span-1 .glue-grid {
    grid-template-columns:repeat(1, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2 {
    display: block;
    grid-column-end: span 2
  }

  .glue-grid .glue-grid__col--span-2 .glue-grid {
    grid-template-columns:repeat(2, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3 {
    display: block;
    grid-column-end: span 3
  }

  .glue-grid .glue-grid__col--span-3 .glue-grid {
    grid-template-columns:repeat(3, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4 {
    display: block;
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-5 {
    display: block;
    grid-column-end: span 5
  }

  .glue-grid .glue-grid__col--span-5 .glue-grid {
    grid-template-columns:repeat(5, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-6 {
    display: block;
    grid-column-end: span 6
  }

  .glue-grid .glue-grid__col--span-6 .glue-grid {
    grid-template-columns:repeat(6, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-7 {
    display: block;
    grid-column-end: span 7
  }

  .glue-grid .glue-grid__col--span-7 .glue-grid {
    grid-template-columns:repeat(7, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-8 {
    display: block;
    grid-column-end: span 8
  }

  .glue-grid .glue-grid__col--span-8 .glue-grid {
    grid-template-columns:repeat(8, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-9 {
    display: block;
    grid-column-end: span 9
  }

  .glue-grid .glue-grid__col--span-9 .glue-grid {
    grid-template-columns:repeat(9, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-10 {
    display: block;
    grid-column-end: span 10
  }

  .glue-grid .glue-grid__col--span-10 .glue-grid {
    grid-template-columns:repeat(10, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-11 {
    display: block;
    grid-column-end: span 11
  }

  .glue-grid .glue-grid__col--span-11 .glue-grid {
    grid-template-columns:repeat(11, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-12 {
    display: block;
    grid-column-end: span 12
  }

  .glue-grid .glue-grid__col--span-12 .glue-grid {
    grid-template-columns:repeat(12, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-18 {
    display: block;
    grid-column-end: span 18
  }

  .glue-grid .glue-grid__col--span-18 .glue-grid {
    grid-template-columns:repeat(18, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-0-lg {
    display: none
  }

  .glue-grid .glue-grid__col--span-1-lg {
    display: block;
    grid-column-end: span 1
  }

  .glue-grid .glue-grid__col--span-1-lg .glue-grid {
    grid-template-columns:repeat(1, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2-lg {
    display: block;
    grid-column-end: span 2
  }

  .glue-grid .glue-grid__col--span-2-lg .glue-grid {
    grid-template-columns:repeat(2, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3-lg {
    display: block;
    grid-column-end: span 3
  }

  .glue-grid .glue-grid__col--span-3-lg .glue-grid {
    grid-template-columns:repeat(3, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4-lg {
    display: block;
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4-lg .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-5-lg {
    display: block;
    grid-column-end: span 5
  }

  .glue-grid .glue-grid__col--span-5-lg .glue-grid {
    grid-template-columns:repeat(5, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-6-lg {
    display: block;
    grid-column-end: span 6
  }

  .glue-grid .glue-grid__col--span-6-lg .glue-grid {
    grid-template-columns:repeat(6, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-7-lg {
    display: block;
    grid-column-end: span 7
  }

  .glue-grid .glue-grid__col--span-7-lg .glue-grid {
    grid-template-columns:repeat(7, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-8-lg {
    display: block;
    grid-column-end: span 8
  }

  .glue-grid .glue-grid__col--span-8-lg .glue-grid {
    grid-template-columns:repeat(8, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-9-lg {
    display: block;
    grid-column-end: span 9
  }

  .glue-grid .glue-grid__col--span-9-lg .glue-grid {
    grid-template-columns:repeat(9, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-10-lg {
    display: block;
    grid-column-end: span 10
  }

  .glue-grid .glue-grid__col--span-10-lg .glue-grid {
    grid-template-columns:repeat(10, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-11-lg {
    display: block;
    grid-column-end: span 11
  }

  .glue-grid .glue-grid__col--span-11-lg .glue-grid {
    grid-template-columns:repeat(11, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-12-lg {
    display: block;
    grid-column-end: span 12
  }

  .glue-grid .glue-grid__col--span-12-lg .glue-grid {
    grid-template-columns:repeat(12, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-18-lg {
    display: block;
    grid-column-end: span 18
  }

  .glue-grid .glue-grid__col--span-18-lg .glue-grid {
    grid-template-columns:repeat(18, minmax(5px, 1fr))
  }
}@media (min-width: 1440px) {
  .glue-grid {
    grid-column-gap: 64px
  }

  .glue-grid .glue-grid__col--span-1 {
    display: block;
    grid-column-end: span 1
  }

  .glue-grid .glue-grid__col--span-1 .glue-grid {
    grid-template-columns:repeat(1, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2 {
    display: block;
    grid-column-end: span 2
  }

  .glue-grid .glue-grid__col--span-2 .glue-grid {
    grid-template-columns:repeat(2, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3 {
    display: block;
    grid-column-end: span 3
  }

  .glue-grid .glue-grid__col--span-3 .glue-grid {
    grid-template-columns:repeat(3, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4 {
    display: block;
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4 .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-5 {
    display: block;
    grid-column-end: span 5
  }

  .glue-grid .glue-grid__col--span-5 .glue-grid {
    grid-template-columns:repeat(5, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-6 {
    display: block;
    grid-column-end: span 6
  }

  .glue-grid .glue-grid__col--span-6 .glue-grid {
    grid-template-columns:repeat(6, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-7 {
    display: block;
    grid-column-end: span 7
  }

  .glue-grid .glue-grid__col--span-7 .glue-grid {
    grid-template-columns:repeat(7, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-8 {
    display: block;
    grid-column-end: span 8
  }

  .glue-grid .glue-grid__col--span-8 .glue-grid {
    grid-template-columns:repeat(8, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-9 {
    display: block;
    grid-column-end: span 9
  }

  .glue-grid .glue-grid__col--span-9 .glue-grid {
    grid-template-columns:repeat(9, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-10 {
    display: block;
    grid-column-end: span 10
  }

  .glue-grid .glue-grid__col--span-10 .glue-grid {
    grid-template-columns:repeat(10, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-11 {
    display: block;
    grid-column-end: span 11
  }

  .glue-grid .glue-grid__col--span-11 .glue-grid {
    grid-template-columns:repeat(11, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-12 {
    display: block;
    grid-column-end: span 12
  }

  .glue-grid .glue-grid__col--span-12 .glue-grid {
    grid-template-columns:repeat(12, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-18 {
    display: block;
    grid-column-end: span 18
  }

  .glue-grid .glue-grid__col--span-18 .glue-grid {
    grid-template-columns:repeat(18, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-0-xl {
    display: none
  }

  .glue-grid .glue-grid__col--span-1-xl {
    display: block;
    grid-column-end: span 1
  }

  .glue-grid .glue-grid__col--span-1-xl .glue-grid {
    grid-template-columns:repeat(1, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-2-xl {
    display: block;
    grid-column-end: span 2
  }

  .glue-grid .glue-grid__col--span-2-xl .glue-grid {
    grid-template-columns:repeat(2, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-3-xl {
    display: block;
    grid-column-end: span 3
  }

  .glue-grid .glue-grid__col--span-3-xl .glue-grid {
    grid-template-columns:repeat(3, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-4-xl {
    display: block;
    grid-column-end: span 4
  }

  .glue-grid .glue-grid__col--span-4-xl .glue-grid {
    grid-template-columns:repeat(4, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-5-xl {
    display: block;
    grid-column-end: span 5
  }

  .glue-grid .glue-grid__col--span-5-xl .glue-grid {
    grid-template-columns:repeat(5, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-6-xl {
    display: block;
    grid-column-end: span 6
  }

  .glue-grid .glue-grid__col--span-6-xl .glue-grid {
    grid-template-columns:repeat(6, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-7-xl {
    display: block;
    grid-column-end: span 7
  }

  .glue-grid .glue-grid__col--span-7-xl .glue-grid {
    grid-template-columns:repeat(7, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-8-xl {
    display: block;
    grid-column-end: span 8
  }

  .glue-grid .glue-grid__col--span-8-xl .glue-grid {
    grid-template-columns:repeat(8, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-9-xl {
    display: block;
    grid-column-end: span 9
  }

  .glue-grid .glue-grid__col--span-9-xl .glue-grid {
    grid-template-columns:repeat(9, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-10-xl {
    display: block;
    grid-column-end: span 10
  }

  .glue-grid .glue-grid__col--span-10-xl .glue-grid {
    grid-template-columns:repeat(10, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-11-xl {
    display: block;
    grid-column-end: span 11
  }

  .glue-grid .glue-grid__col--span-11-xl .glue-grid {
    grid-template-columns:repeat(11, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-12-xl {
    display: block;
    grid-column-end: span 12
  }

  .glue-grid .glue-grid__col--span-12-xl .glue-grid {
    grid-template-columns:repeat(12, minmax(5px, 1fr))
  }

  .glue-grid .glue-grid__col--span-18-xl {
    display: block;
    grid-column-end: span 18
  }

  .glue-grid .glue-grid__col--span-18-xl .glue-grid {
    grid-template-columns:repeat(18, minmax(5px, 1fr))
  }
}
}

.glue-icon--32px {
  height: 32px;
  width: 32px;
}

.glue-icon--social {
  transition: fill .2s;
}

.glue-icon--color-sharelink:hover {
  fill: var(--link-hover-grey);
}

.glue-icon--color-linkedin:hover {
  fill: #0077b5;
}

.glue-icon--color-instagram:hover {
  fill: #df465f;
}

a {
  background: transparent;
  border-radius: 4px;
  color: var(--red);
  display: inline;
  overflow: hidden;
  text-decoration: underline;
  transition: all .2s;
}

a:hover {
  color: var(--red);
}

a:hover {
  cursor: pointer;
  outline: none;
}

a:hover {
  background-color: rgba(26, 115, 232, .04);  /* TODO */
}

a img {
  border: 0;
}

.glue-headline, .page-title__footer p {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  word-wrap: normal;
  font-weight: 400;
  overflow-wrap: normal;
  text-rendering: optimizeLegibility;
}

body, html {
  font-size: 1em;
}

body {
  word-wrap: break-word;
  background: var(--background);
  color: var(--text-primary);
  font-family: Arial, Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.444;
  margin: 0;
  overflow-wrap: break-word;
  padding: 0;
}

h1, h2 {
  font-size: 1.125rem;
  font-weight: 300;
}

p {
  font-size: 1.2rem;
  letter-spacing: .25px;
  line-height: 1.5;
}

p + p {
  margin-top: 12px;
}

@media (min-width: 1024px) {
  p {
    letter-spacing: 0;
  }

  p + p {
    margin-top: 16px;
  }
}

ul {
  list-style-position: outside;
  margin-bottom: 20px;
}

ul.glue-no-bullet {
  list-style: none;
}

ul {
  margin-inline-start: 20px;
}

ul.glue-no-bullet {
  margin-inline-start: 0;
}

li {
  font-size: 1.2rem;
  letter-spacing: .25px;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  li {
    letter-spacing: 0;
  }
}

.glue-headline, .page-title__footer p {
  color: var(--text-primary);
  font-family: Roboto, sans-serif;
}

.glue-headline.glue-headline--headline-2 {
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.3;
}

/* put bold red slash before */
.glue-headline.glue-headline--headline-2:before {
  content: "/";
  font-weight: 600;
  font-family: Roboto, sans-serif;
  color: var(--red);
  margin-right: 8px;
}

@media (min-width: 600px) {
  .glue-headline.glue-headline--headline-2 {
    font-size: 2.5rem;
    letter-spacing: -.5px;
    line-height: 1.2;
  }
}

@media (min-width: 1024px) {
  .glue-headline.glue-headline--headline-2 {
    font-size: 3rem;
    line-height: 1.1666666667;
  }
}

.glue-headline.glue-headline--headline-2 {
  font-family: Roboto, sans-serif;
}

.glue-headline.glue-headline--headline-3 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.3333333333;
}

@media (min-width: 600px) {
  .glue-headline.glue-headline--headline-3 {
    font-size: 2rem;
    letter-spacing: -.25px;
    line-height: 1.25;
  }
}

@media (min-width: 1024px) {
  .glue-headline.glue-headline--headline-3 {
    font-size: 2.25rem;
    line-height: 1.2222222222;
  }
}

.glue-headline.glue-headline--headline-3 {
  font-family: Roboto, sans-serif;
}

.glue-headline.glue-headline--headline-5, .page-title__footer p {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.4;
}

.glue-headline.glue-headline--headline-5, .page-title__footer p {
  font-family: Roboto, sans-serif;
}

.page-cover__description > p {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: .1px;
  line-height: 1.5
}

.glue-caption {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .15px;
  line-height: 1.7142857143;
}

.glue-cards {
  list-style: none;
  margin: 0;
}

/* TODO: change box shadows */
.glue-card {
  border-radius: 12px;
  box-shadow: 2px 4px 12px #00000010;
  display: block;
  height: 100%;
  min-height: 96px;
  outline: 0;
  overflow: hidden;
  text-decoration: none;
  transform: translateZ(0);
  transition: all .2s cubic-bezier(0,0,.5,1);
  width: 100%;
}

.glue-card, .glue-card:hover {
  background: white;
}

.glue-card:hover {
  box-shadow: 2px 4px 16px #00000020;
  transform: scale3d(1.01,1.01,1.01);
}

.apply-card:hover {
  transform: scale3d(1.004,1.004,1.004) !important;
}

.glue-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 94px;
  position: relative;
  width: 100%;
}

.glue-card__inner:before {
  border-radius: 12px;
  content: "";
  height: 100%;
  position: absolute;
  transition: border .25s;
  width: 100%;
  z-index: -1;
}

.glue-card__asset {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.glue-card__asset img {
  display: block;
  width: 100%;
}

.glue-card__content {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  padding: 24px 24px 48px 24px;
  width: 100%;
}

.glue-card__content .glue-headline {
  color: var(--text-primary);
}

.glue-card__content .glue-card__description, .glue-card__content .glue-caption {
  color: var(--link-hover-grey);
}

.glue-card__content .glue-caption, .glue-card__content .glue-card__description, .glue-card__content .glue-headline {
  display: block;
  margin-top: 8px;
}

.glue-card__content .glue-headline:first-child {
  margin-top: 0;
}

.glue-card__description {
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: .1px;
  line-height: 1.5;
}

.glue-card__cta {
  margin-top: auto;
  padding: 15px 11px 11px;
}

.glue-card__cta .glue-button {
  border: 0;
  margin: 0;
  padding: 13px;
  pointer-events: none;
}

@media (-ms-high-contrast: active),(forced-colors: active) {
  .glue-card__cta .glue-button {
    background: window;
    color: linkText;
  }
}

.glue-card__cta--arrow {
  align-self: flex-end;
}

.glue-card__cta--arrow .glue-button {
  border-radius: 100%;
}

.glue-card__cta--arrow .glue-button .glue-icon {
  display: block;
  height: 24px;
  width: 24px;
}

.glue-card--list .glue-card__inner {
  align-items: center;
  flex-flow: row wrap;
}

.glue-card--list .glue-card__content {
  order: -1;
  padding: 16px 16px 0;
  width: calc(100% - 108px);
}

.glue-card--list .glue-card__content:last-child {
  padding-bottom: 16px;
}

.glue-card--list .glue-card__content .glue-caption, .glue-card--list .glue-card__content .glue-headline {
  margin-top: 4px;
}

.glue-card--list .glue-card__content .glue-headline:first-child {
  margin-top: 0;
}

.glue-footer {
  background: var(--off-white);
  margin: 0;
}

.glue-footer__link {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--link-hover-grey);
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
  min-height: 48px;
  min-width: 48px;
  padding: 12px 0;
  text-decoration: none;
  text-rendering: optimizeLegibility;
}

@media (min-width: 1024px) {
  .glue-footer__link {
    min-height: 0;
    min-width: 0;
    padding: 0;
  }
}

.glue-footer__link:visited {
  color: var(--link-hover-grey);
}

.glue-footer__link:hover, .glue-footer__link:visited:hover {
  color: var(--text-primary);
}

.glue-footer__link:hover {
  background: var(--off-white);
}

.glue-footer .glue-social .glue-social__list {
  align-self: center;
}

.glue-footer .glue-social .glue-social__item {
  margin-bottom: 0;
}

.glue-footer__site-links {
  border-bottom: 1px solid var(--light-grey);
}

@media (max-width: 599px) {
  .glue-footer .glue-footer__site-links {
    margin: 0;
    padding: 0 28px;
  }
}

@media (min-width: 1024px) {
  .glue-footer__site-links {
    padding: 20px 0 24px;
  }
}

.glue-footer__site-links-grid {
  padding: 24px 0 0;
}

@media (min-width: 1024px) {
  .glue-footer__site-links-grid {
    box-sizing: content-box;
    display: flex;
    padding-top: 0
  }

  @supports (display:grid) {
    .glue-footer__site-links-grid {
      grid-column-gap: 24px;
      display: grid;
      grid-template-columns:repeat(4, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid.glue-footer__site-links-grid--1-col {
      grid-template-columns:repeat(1, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid.glue-footer__site-links-grid--2-col {
      grid-template-columns:repeat(2, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid.glue-footer__site-links-grid--3-col {
      grid-template-columns:repeat(3, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid.glue-footer__site-links-grid--4-col {
      grid-template-columns:repeat(4, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid.glue-footer__site-links-grid--5-col {
      grid-template-columns:repeat(5, minmax(100px, 1fr))
    }

    .glue-footer__site-links-grid {
      grid-column-gap: 40px
    }
  }
}

.glue-footer__site-links-column {
  margin: 0 0 24px;
  padding: 0;
}

@media (min-width: 1024px) {
  .glue-footer__site-links-column {
    margin-inline-end: 24px;
    width: calc(25% - 24px)
  }

  @supports (display:grid) {
    .glue-footer__site-links-column {
      margin: 0;
      width: 100%
    }
  }.glue-footer__site-links-grid--1-col .glue-footer__site-links-column {
     width: calc(100% - 24px)
   }

  .glue-footer__site-links-grid--2-col .glue-footer__site-links-column {
    width: calc(50% - 24px)
  }

  .glue-footer__site-links-grid--3-col .glue-footer__site-links-column {
    width: calc(33% - 24px)
  }

  .glue-footer__site-links-grid--4-col .glue-footer__site-links-column {
    width: calc(25% - 24px)
  }

  .glue-footer__site-links-grid--5-col .glue-footer__site-links-column {
    width: calc(20% - 24px);
  }

  @supports (display:grid) {
    .glue-footer__site-links-grid--1-col .glue-footer__site-links-column, .glue-footer__site-links-grid--2-col .glue-footer__site-links-column, .glue-footer__site-links-grid--3-col .glue-footer__site-links-column, .glue-footer__site-links-grid--4-col .glue-footer__site-links-column, .glue-footer__site-links-grid--5-col .glue-footer__site-links-column {
      width: 100%
    }
  }
}

.glue-footer__site-links-list ul {
  margin-bottom: 0;
}

.glue-footer__site-links-list-item {
  margin-bottom: 8px;
}

.glue-footer__site-links-list-item:first-child {
  margin-top: 8px;
}

.glue-footer__site-links-list-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 599px) {
  .glue-footer__site-links-list-item {
    margin-inline-start: 2px;
  }
}

.glue-header__logo .glue-header__logo--product {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  word-wrap: normal;
  color: var(--dark-gray);
  font-family: Orbitron, sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  hyphens: auto;
  letter-spacing: .25px;
  overflow-wrap: normal;
  padding-inline-start: 5px;
  text-decoration: none;
}

.glue-header {
  background-color: #fff;
  font-family: sans-serif;
  min-height: 64px;
  width: 100%;
  z-index: 100;
}

.glue-header .glue-header__bar {
  background: #fff;
  box-shadow: 0 0 0 0 transparent;
  transition: transform .3s ease, background .3s ease, box-shadow .3s ease;
  width: 100%;
}

.glue-header .glue-header__bar .glue-header__tier {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 64px;
}

@media (min-width: 1024px) {
  .glue-header .glue-header__bar .glue-header__tier {
    align-items: flex-start;
    flex-direction: row;
  }
}

.glue-header .glue-header__bar.glue-header__bar--desktop .glue-header__tier {
  height: 100%;
}

.glue-header .glue-header__bar.glue-header__bar--desktop .glue-header__tier:first-child .glue-header__container:not(.glue-header__container--cta):not(.glue-header__site-switcher-menu) {
  position: relative;
}

.glue-header .glue-header__bar.glue-header__bar--mobile {
  display: block;
  position: fixed;
  z-index: 800;
}

@media (min-width: 1024px) {
  .glue-header .glue-header__bar.glue-header__bar--mobile {
    display: none;
  }
}

.glue-header .glue-header__bar.glue-header__bar--mobile .glue-header__tier {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 64px;
}

.glue-header.glue-header--no-cta {
  min-height: 64px;
}

.glue-header.glue-header--no-cta .glue-header__bar--mobile:after {
  border-bottom: 1px solid var(--light-grey);
  content: " ";
  display: none;
  height: 0;
  left: 0;
  position: absolute;
  top: 64px;
  width: 100%;
}

.glue-header.glue-header--single {
  min-height: 128px;
}

@media (min-width: 600px) {
  .glue-header.glue-header--single {
    min-height: 64px;
  }
}

@media (min-width: 1024px) {
  .glue-header.glue-header--single .glue-header__bar {
    height: 64px;
  }
}

.glue-header.glue-header--single .glue-header__bar--mobile:after {
  border-bottom: 1px solid var(--light-grey);
  content: " ";
  display: none;
  height: 0;
  left: 0;
  position: absolute;
  top: 64px;
  width: 100%;
}

@media (max-width: 599px) {
  .glue-header.glue-header--single .glue-header__bar--mobile .glue-header__tier {
    flex-direction: column;
  }

  .glue-header.glue-header--single .glue-header__bar--mobile .glue-header__tier:after {
    border-bottom: 1px solid var(--light-grey);
    content: " ";
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    top: 63px;
    width: 100%;
  }

  .glue-header.glue-header--single .glue-header__bar--mobile:not(.glue-header--rewind-box-shadow):after {
    border-bottom: 1px solid var(--light-grey);
    content: " ";
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    top: 128px;
    width: 100%;
  }
}

.glue-header.glue-header--single.glue-header--no-cta {
  height: 64px;
  min-height: 64px;
}

@media (max-width: 599px) {
  .glue-header.glue-header--single.glue-header--no-cta .glue-header__bar--mobile {
    height: 64px;
    min-height: 64px;
    z-index: 800;
  }
}

@media (max-width: 599px)and (max-width: 599px) {
  .glue-header.glue-header--single.glue-header--no-cta .glue-header__bar--mobile:not(.glue-header--rewind-box-shadow):after {
    border-bottom: 1px solid var(--light-grey);
    content: " ";
    display: none;
    height: 0;
    left: 0;
    position: absolute;
    top: 128px;
    width: 100%;
  }
}

@media (max-width: 599px) {
  .glue-header.glue-header--single.glue-header--no-cta .glue-header__bar--mobile:after {
    border-bottom: 1px solid var(--light-grey);
    content: " ";
    display: none;
    height: 0;
    left: 0;
    position: absolute;
    top: 64px;
    width: 100%;
  }
}

.glue-header__container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  transition: background .3s ease;
}

@media (min-width: 1024px) {
  .glue-header__container {
    height: 100%;
  }
}

.glue-header__container--linkbar {
  flex: none;
}

@media (min-width: 1024px) {
  .glue-header__container--linkbar {
    flex: auto;
  }
}

@media (min-width: 600px) {
  .glue-header__tier .glue-header__container:last-child {
    margin-inline-end: 8px;
  }
}

@media (max-width: 599px) {
  .glue-header--no-cta .glue-header__bar--mobile .glue-header__tier .glue-header__container:last-child {
    padding-inline: 0;
  }
}

.glue-header__hamburger {
  margin-inline-start: 12px;
}

@media (min-width: 1024px) {
  .glue-header__hamburger {
    display: none;
  }
}

.glue-header__drawer-toggle-btn {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 4px;
  color: var(--link-hover-grey);
  display: flex;
  flex-direction: row;
  font: inherit;
  height: 48px;
  justify-content: center;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 48px;
}

.glue-header__drawer-toggle-btn:hover {
  background-color: var(--off-white);
  box-shadow: 0 0 0 2px var(--red);
  outline: none;
}

.glue-header__drawer-toggle-btn > svg {
  pointer-events: none;
}

@media (-ms-high-contrast: active),(forced-colors: active) {
  .glue-header__drawer-toggle-btn > svg {
    fill: windowText;
  }
}

.glue-header__bar--desktop, .glue-header__drawer {
  border: 0 solid red;
  bottom: 0;
  height: 100%;
  inset-inline-end: 56px;
  inset-inline-start: 0;
  max-width: 400px;
  position: fixed;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  visibility: hidden;
  z-index: 820;
}

@media (min-width: 1024px) {
  .glue-header__bar--desktop, .glue-header__drawer {
    visibility: visible;
  }
}

@media (min-width: 1024px) {
  .glue-header__bar--desktop, .glue-header__drawer {
    display: block;
    max-width: 100%;
    transform: none;
  }
}

.glue-header__drawer-backdrop {
  background: rgba(0, 0, 0, .4);
  bottom: 0;
  display: none;
  inset-inline-end: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1), width 3s cubic-bezier(.4, 0, .2, 1);
  width: 100%;
  z-index: 810;
}

.glue-header__drawer-backdrop:not(.glue-is-showing-drawer .glue-header__drawer-backdrop) {
  pointer-events: none;
}

@media (min-width: 1024px) {
  .glue-header__drawer-backdrop {
    display: none;
  }
}

.glue-header__link-bar {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .glue-header__link-bar {
    display: initial;
    flex-direction: row;
    height: 100%;
    overflow: visible;
    padding-inline-start: 48px;
    padding-top: 0;
    position: relative;
  }
}

.glue-header__list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .glue-header__list {
    flex-direction: row;
    min-height: 64px;
  }
}

.glue-header__link-bar > .glue-header__list {
  padding-block: 30px 23px;
}

@media (min-width: 1024px) {
  .glue-header__link-bar > .glue-header__list {
    padding: 0;
  }
}

.glue-header__item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.glue-header--single .glue-header__item {
  position: relative;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .glue-header__item {
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .glue-header__item {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 8px 0;
    width: auto;
  }
}

.glue-header__link, .glue-header__logo-link {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  height: 48px;
  letter-spacing: .25px;
  line-height: 1.5;
  overflow: hidden;
  padding-inline-end: 12px;
  padding-inline-start: 20px;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  transition: color .2s;
  white-space: nowrap;
  width: calc(100% - 8px);
}

.glue-header__link:visited, .glue-header__logo-link:visited {
  color: #000;
}

.glue-header__link:hover, .glue-header__logo-link:hover {
  background-color: white;
  color: var(--link-hover-grey);
}

@media (min-width: 1024px) {
  .glue-header__link, .glue-header__logo-link {
    padding-inline-start: 12px;
    width: auto;
  }
}

@media (-ms-high-contrast: active),(forced-colors: active) {
  .glue-header__link, .glue-header__logo-link {
    color: linkText;
  }
}

.glue-header--is-active > .glue-header__link, .glue-header--is-active > .glue-header__logo-link {
  color: var(--text-primary)
}

@media (min-width: 1024px) {
  .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link, .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link {
    overflow: visible;
    position: relative
  }

  .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link:before, .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link:before {
    background-color: #fff;
    border-bottom: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
    bottom: -12px;
    content: " ";
    height: 10px;
    left: calc(50% - 5px);
    position: absolute;
    transform: rotate(135deg);
    width: 10px;
    z-index: 850
  }

  .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link:before, .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link:before {
    background-color: transparent
  }

  .glue-header--transparent:not(.glue-header--active) .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link:before, .glue-header--transparent:not(.glue-header--active) .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link:before {
    border-color: var(--red)
  }

  .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link div, .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link div {
    height: 100%;
    position: absolute;
    width: 100%
  }

  .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link div:before, .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link div:before {
    background-color: var(--red);
    bottom: -8px;
    content: " ";
    height: 1px;
    left: calc(-100vw + 50% - 14.14214px);
    pointer-events: none;
    position: absolute;
    width: calc(100vw - 5px)
  }

  .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link div:after, .glue-header--transparent .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link div:after {
    background-color: var(--red);
    bottom: -8px;
    content: " ";
    height: 1px;
    pointer-events: none;
    position: absolute;
    right: calc(-100vw + 50% + 10px);
    width: calc(100vw - 5px);
    z-index: 100
  }

  .glue-header--transparent.glue-header--active .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link div:after, .glue-header--transparent.glue-header--active .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__link div:before, .glue-header--transparent.glue-header--active .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link div:after, .glue-header--transparent.glue-header--active .glue-header__link-bar > .glue-header__list > .glue-header--is-active > .glue-header__logo-link div:before {
    background-color: var(--light-grey)
  }
}

.glue-header__item--active .glue-header__link, .glue-header__item--active .glue-header__logo-link {
  background-color: var(--off-white);
  color: var(--text-primary)
}

.glue-header--transparent .glue-header__item--active .glue-header__link, .glue-header--transparent .glue-header__item--active .glue-header__logo-link {
  background-color: transparent
}

@media (min-width: 1024px) {
  .glue-header__item--active .glue-header__link, .glue-header__item--active .glue-header__logo-link {
    background-color: #fff
  }

  .glue-header__item--active .glue-header__link .glue-header--transparent, .glue-header__item--active .glue-header__logo-link .glue-header--transparent {
    background-color: transparent
  }
}

.glue-header__item--active .glue-header__link:hover, .glue-header__item--active .glue-header__logo-link:hover {
  background-color: white;
}

@media (min-width: 1024px) {
  .glue-header__item--active .glue-header__link:after, .glue-header__item--active .glue-header__logo-link:after {
    background-color: var(--red);
    border-bottom: 3px solid var(--red);
    bottom: 0;
    content: "";
    height: 0;
    left: 12px;
    position: absolute;
    width: calc(100% - 24px);
    z-index: 840
  }
}

.glue-header__skip-content {
  display: flex;
  flex-direction: row;
  height: 1px;
  inset-inline-start: -10px;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}

@media (min-width: 1024px) {
  .glue-header__skip-content {
    padding: 0;
    visibility: visible;
  }
}

.glue-header__lock-up {
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 64px;
  min-height: 64px;
  position: relative;
  width: 100%;
}

@media (min-width: 1024px) {
  .glue-header__lock-up {
    width: auto;
  }
}

.glue-header__logo {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  max-height: 48px;
  transition: all .2s;
}

.glue-header__logo:visited {
  color: var(--link-hover-grey);
}

.glue-header__logo:hover {
  background-color: var(--off-white);
  color: var(--text-primary);
  outline: none;
}

@media (min-width: 1024px) {
  .glue-header__logo:hover {
    border-radius: 4px;
  }
}

@media (min-width: 1024px) {
  .glue-header__logo {
    margin-inline-start: 8px;
  }
}

.glue-header__logo > .glue-header__logo-link {
  width: auto;
}

.glue-header__drawer .glue-header__logo {
  margin-inline-start: 8px;
}

@media (min-width: 1024px) {
  .glue-header__logo {
    flex: auto;
  }
}

.glue-header__logo-link {
  border-radius: 4px;
  padding: 0 9px;
  width: auto;
}

.glue-header__logo-link:hover {
  background-color: initial;
}

.glue-header__bar--mobile .glue-header__logo-link {
  padding-inline: 10px;
}

.glue-header__logo-container {
  height: 45px;
  width: 75px;
}

.glue-header__logo-container .glue-header__logo-svg {
  fill: currentColor;
  height: 49px;
  width: 75px;
}

@media (min-width: 600px) {
  .glue-header__logo-container .glue-header__logo-svg, .glue-header__logo-container .glue-header__logo-svg use {
    height: 49px;
    width: 75px;
  }
}

.glue-social__item {
  margin-inline: 0 8px;
}

.glue-social {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.glue-social__group {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin: 0;
  max-width: 100%;
}

.glue-social__list {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  max-width: 100%;
  justify-content: center;
}

@media (min-width: 600px) {
  .glue-social__list {
    flex-wrap: nowrap;
  }
}

.glue-social__item {
  align-items: center;
  align-self: center;
  display: inline-flex;
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
}

.glue-social__item:last-child {
  margin-inline-end: 0;
}

.glue-social__item .glue-social__link {
  align-items: center;
  color: currentColor;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  transition: none;
  transition-property: none;
  width: 32px;
}

html {
  height: 100%;
}

body {
  background: var(--background);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 300px;
}

a, button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  margin-bottom: 0;
}

picture img {
  display: block;
  width: 100%;
}

.glue-header {
  z-index: 2;
}

.glue-headline {
  word-break: break-word;
}

:root {
  --scrollbar-width: 0px;
  --viewport-width: calc(100vw - var(--scrollbar-width));
  --grid-gutter: 28px;
  --page-gutter: 28px;
  --header-height: 64px;
  --spacer-1: 8px;
  --spacer-2: 16px;
  --spacer-3: 24px;
  --spacer-4: 36px;
  --spacer-5: 48px;
  --spacer-6: 60px;
  --spacer-7: 92px;
  --spacer-8: 136px;
}

@media (min-width: 600px) {
  :root {
    --grid-gutter: 40px;
    --page-gutter: 40px;
  }
}

@media (min-width: 1024px) {
  :root {
    --col-6-width: calc(16.66667% - var(--grid-gutter) * 5 / 6);
    --grid-gutter: 48px;
    --page-gutter: 72px;
    --spacer-5: 60px;
    --spacer-6: 80px;
    --spacer-7: 120px;
    --spacer-8: 180px;
  }
}

@media (min-width: 1440px) {
  :root {
    --grid-gutter: 64px;
    --page-gutter: 72px;
  }
}

.card-list {
  margin: 0 -28px 0;
  padding: var(--spacer-6) 28px var(--spacer-6);
  background-color: var(--off-white);
}

@media (min-width: 600px) {
  .card-list {
    margin: 0 -40px 0;
    padding: var(--spacer-6) 40px var(--spacer-6);
  }
}

@media (min-width: 1024px) {
  .card-list {
    margin: 0 -72px 0;
    padding: var(--spacer-6) 72px var(--spacer-6);
  }
}

@media (min-width: 1440px) {
  .card-list {
    margin: 0 calc((-100vw + 1296px) / 2) 0;
    padding: var(--spacer-6) calc((100vw - 1296px) / 2) var(--spacer-6);
  }
}

.cards {
  grid-auto-rows: 1fr;
  margin: 0 -28px var(--spacer-6);
  padding: var(--spacer-5) 28px var(--spacer-6);
  background-color: var(--off-white);
}

@media (min-width: 600px) {
  .cards {
    margin: 0 -40px var(--spacer-6);
    padding: var(--spacer-5) 40px var(--spacer-6);
  }
}

@media (min-width: 1024px) {
  .cards {
    margin: 0 -72px var(--spacer-6);
    padding: var(--spacer-5) 72px var(--spacer-6);
  }
}

@media (min-width: 1440px) {
  .cards {
    margin: 0 calc((-100vw + 1296px) / 2) var(--spacer-5);
    padding: var(--spacer-5) calc((100vw - 1296px) / 2) var(--spacer-6);
  }
}

.sponsors {
  margin: var(--spacer-5) 0 var(--spacer-6);
}

.card-list .glue-cards {
  gap: 8px;
}

@media (min-width: 600px)and (max-width: 1023px) {
  .card-list .glue-card__content .glue-grid {
    display: block;
  }
}

@media (min-width: 1024px) {
  .card-list .glue-card__inner {
    display: grid;
    grid-template-columns:1fr auto;
  }

  .card-list .glue-card, .card-list .glue-card__inner {
    min-height: 0;
  }

  .card-list .glue-card__content {
    padding-block: 14px;
    padding-inline: 0;
    width: 100%;
    z-index: 1;
  }

  .card-list .glue-card__content .glue-grid > :first-child {
    padding-inline-start: 16px;
  }

  .card-list .glue-card__content .glue-grid > :last-child {
    padding-inline-end: 16px;
  }

  .card-list .glue-grid > * {
    align-self: center;
  }

  .card-list .glue-card__content .glue-caption {
    margin-top: 0;
  }
}

.card .glue-card__description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.card .glue-card__asset {
  aspect-ratio: 16/9;
  overflow: hidden;
}

.card .glue-card__asset img {
  height: 100%;
  object-fit: cover;
}

.cta {
  margin: 0 auto;
  max-width: none;
}

.phx-footer {
  margin-top: auto;
}

.phx-footer .glue-footer__site-links {
  border-bottom-width: 0;
}

@media (min-width: 1024px) {
  .phx-footer .glue-footer__site-links {
    border-top-width: 1px;
  }
}

.phx-footer .glue-footer__link {
  background-color: rgba(248, 249, 250, 0);
}

.phx-footer .glue-footer__site-links-grid {
  gap: var(--grid-gutter);
  padding: 0;
}

.phx-header__search-wrapper {
  display: grid;
}

.phx-header__search-wrapper > * {
  grid-area: 1/-1;
}

.phx-header__search-wrapper--mobile {
  width: 100%;
}

.phx-header .glue-header__bar--mobile .glue-header__container {
  width: 100%;
}

.phx-header__backdrop {
  display: block;
  pointer-events: auto;
}

.glue-header.glue-header--no-cta .glue-header__bar--desktop .glue-header__tier, .glue-header.glue-header--no-cta .glue-header__drawer .glue-header__tier {
  max-height: none;
}

.glue-header__logo:hover {
  background-color: inherit;
  color: inherit;
}

.glue-header__logo-link {
  transition: all .2s;
}

.glue-header__logo-link:visited {
  color: var(--link-hover-grey);
}

.glue-header__logo-link:hover {
  background-color: white;
  color: var(--text-primary);
  outline: none;
}

.page-title {
  margin: var(--spacer-7) auto;
  max-width: 1000px;
  text-align: center;
  width: 100%;
}

.page-title__footer {
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
}

.page-title__footer p {
  font-size: 1.5rem;
  margin-top: var(--spacer-1);
}

.page-cover {
  align-items: center;
  margin-bottom: var(--spacer-4);
}

.page-cover__image {
  height: 320px;
  overflow: hidden;
  width: 100%;
}

@media (min-width: 600px) {
  .page-cover__image {
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .page-cover__image {
    height: auto
  }

  .page-cover--right .page-cover__image {
    order: 2
  }
}

.page-cover__image img {
  height: 100%;
  object-fit: cover;
}

.page-cover__content {
  margin: var(--spacer-7) auto 0;
  max-width: 616px;
  width: 100%;
}

@media (min-width: 1024px) {
  .page-cover__content {
    margin: var(--spacer-3) auto;
  }
}

.page-cover__content .glue-button {
  margin: var(--spacer-1) 0 0;
}

.page-cover__title {
  color: var(--text-primary);
  transition: color var(--transition)
}

.page-cover__description {
  color: var(--text-secondary);
  width: 100%
}

.page-cover__description > p {
  margin: var(--spacer-3) 0 0;
  transition: color var(--transition)
}

.page-cover__content--centered {
  max-width: 1000px;
  padding-right: 0;
  text-align: center;
  width: 100%;
}

.page-cover--inset {
  padding: var(--spacer-3) 0
}

.page-cover--inset .page-cover__image {
  border-radius: 12px
}

@media (min-width: 1024px) {
  .page-cover--inset .page-cover__image {
    aspect-ratio: 1;
  }
}

.page-cover--inset .page-cover__content .glue-social {
  margin: var(--spacer-3) 0
}

.page-cover--full {
  background: var(--background-mono);
  height: 100vh;
  margin-top: calc(var(--header-height) * -1);
  max-height: 1200px;
  min-height: 400px;
  position: relative;
}

.page-cover--full .page-cover__image {
  --margin: calc(var(--page-gutter) * -1);
  height: 100%;
  margin-left: var(--margin);
  margin-right: var(--margin);
  max-width: var(--viewport-width);
  min-width: 300px;
  pointer-events: none;
  position: absolute;
  width: var(--viewport-width);
}

@media (min-width: 1440px) {
  .page-cover--full .page-cover__image {
    --margin: 0;
  }
}

.page-cover--full .page-cover__content {
  margin-block: calc(var(--header-height) + var(--spacer-3));
  position: relative;
}

.promo-card-group {
  grid-row-gap: var(--grid-gutter);
  margin: var(--spacer-4) 0 var(--spacer-6);
}

.promo-card-group .promo {
  margin-block: 0;
}

.promo {
  margin: 0 -28px;
  z-index: 0;
  color: var(--text-primary);
  position: relative;
}

@media (min-width: 600px) {
  .promo {
    margin: 0 -40px;
  }
}

@media (min-width: 1024px) {
  .promo {
    margin: 0 -72px;
  }
}

@media (min-width: 1440px) {
  .promo {
    margin: 0 calc((-100vw + 1296px) / 2);
  }
}

.goals {
  margin: var(--spacer-2) 0;
  color: var(--text-primary);
  position: relative;
  border-radius: 12px;
  box-shadow: 2px 4px 12px #00000010
}

.goals1 {
  background-color: #F127163D;
}

/* grey */
.goals2 {
  background-color: #9AA0A63D;
}

.goals3 {
  background-color: #1E90FF3D;
}

.promo__inner {
  display: grid;
  min-height: 460px;
  padding: var(--spacer-4);
  position: relative;
}

@media (min-width: 1024px) {
  .promo__inner {
    min-height: 780px;
  }
}

.goals__inner {
  display: grid;
  min-height: 460px;
  padding: var(--spacer-4);
  position: relative;
}

@media (min-width: 1024px) {
  .goals__inner {
    min-height: 420px;
  }
}

.promo__background-image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  width: 100%;
  border-bottom: 4px solid var(--red);
}

.promo__text {
  max-width: 616px;
  width: 100%;
}

.promo__text h2, .promo__text p {
  color: inherit;
}

.promo__description {
  margin-top: var(--spacer-1);
}

.promo__cta {
  margin: auto auto 0 0;
}

.promo--full-width:not(.promo--form) .promo__inner {
  grid-template-rows:1fr auto 1fr;
  justify-content: center;
}

.promo--full-width:not(.promo--form) .promo__inner .promo__text {
  grid-row: 2;
  padding: var(--spacer-4) 0;
  text-align: center;
}

.promo--full-width:not(.promo--form) .promo__inner .promo__cta {
  grid-row: 3;
  margin: auto auto 0;
}

.promo--inverted {
  background-color: #060606;
  color: #fff;
}

.promo--full-width.promo--icon .promo__inner {
  grid-template-rows:0 auto min-content;
}

.promo--full-width.promo--icon .promo__inner .promo__text {
  align-self: center;
  padding-top: var(--spacer-1);
}

.promo--full-width.promo--icon .promo__inner .promo__cta {
  margin-top: 0;
}

.promo--full-width.promo--large-text .promo__text {
  max-width: 1000px;
  width: 100%;
}

.promo--full-width.promo--large-text .promo__headline {
  font-size: 2.625rem;
  line-height: 1.1904761905;
  font-family: Orbitron, sans-serif;
}

@media (min-width: 600px) {
  .promo--full-width.promo--large-text .promo__headline {
    font-size: 3.5rem;
    line-height: 1.1111111111;
  }
}

@media (min-width: 1024px) {
  .promo--full-width.promo--large-text .promo__headline {
    font-size: 4rem;
    line-height: 1.1428571429;
  }
}

.share--centered.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(4) {
  transform: translate(156px)
}

.share--centered.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(5) {
  transform: translate(208px)
}

.share--centered .glue-social__group {
  align-items: center
}

.share--centered .glue-social__title.glue-social__title--zippy.glue-expansion-panel__button {
  align-self: center
}

@media (max-width: 599px) {
  .share--left .glue-social__group {
    flex-direction: column
  }

  .share--left .glue-social__title.glue-social__title--zippy.glue-expansion-panel__button {
    margin: 0
  }

  .share--left .glue-expansion-panel .glue-social__list {
    height: 52px;
    padding-top: 4px;
    width: 256px
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item {
    transition-duration: 50ms
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(0) {
    transform: translate(-52px)
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:first-child {
    transform: translate(0)
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(2) {
    transform: translate(52px)
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(3) {
    transform: translate(104px)
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(4) {
    transform: translate(156px)
  }

  .share--left.glue-social--zippy .glue-is-collapsed .glue-social__item:nth-child(5) {
    transform: translate(208px)
  }

  .share--left .glue-social__group {
    align-items: flex-start
  }
}

.accordion {
  margin: var(--spacer-6) auto;
  max-width: 1000px;
  width: 100%
}

.accordion .glue-expansion-panel__button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.accordion .glue-expansion-panel__content > div {
  margin-block: var(--spacer-3);
  padding: 0
}

.bookmark {
  scroll-margin-top: calc(var(--header-height) + var(--spacer-4));
}